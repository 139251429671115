import { getBoardBackground } from "../../services/api/trelloApi";
import { hexToAdjustedHex } from "../../utils/modalTopColorConverter";
import CardRelationshipsIcon from "../../assets/images/card-relationships-icon-main.svg";

window.TrelloPowerUp.initialize(
  {
    "board-buttons": function (t, opts) {
      return [
        {
          icon: CardRelationshipsIcon,
          text: process.env.POWERUP_NAME,
          condition: "edit",
          callback: async function (t) {
            const token = await t.get("member", "private", "token");
            if (!token || token.includes("rejected")) {
              // return Auth modal if the user is not yet authorized
              return t.modal({
                title: "",
                accentColor: "#1064E3",
                url: "./authorize.html",
                height: 593,
                args: { trigger: "show-authorization" },
              });
            } else {
              return openModal(t);
            }
          },
        },
      ];
    },
    "card-back-section": function (t, opts) {
      return {
        title: "Relationships",
        icon: CardRelationshipsIcon,
        content: {
          type: "iframe",
          url: t.signUrl("./card-back.html"),
        },
        action: {
          text: "View all",
          callback: async function (t) {
            const token = await t.get("member", "private", "token");
            if (!token || token.includes("rejected")) {
              // return Auth modal if the user is not yet authorized
              return t.modal({
                title: "",
                accentColor: "#1064E3",
                url: "./authorize.html",
                height: 593,
                args: { trigger: "show-authorization" },
              });
            } else {
              return openModal(t);
            }
          },
        },
      };
    },
    "show-settings": function (t, opts) {
      return t.popup({
        title: "Card Relationships Settings",
        url: "./settings.html",
      });
    },
    "on-enable": async function (t, opts) {
      const existingCardRelationships = await t.get(
        "organization",
        "shared",
        "cardRelationships"
      );

      if (existingCardRelationships === undefined) {
        await t.set("organization", "shared", "cardRelationships", {});
      }

      return t.modal({
        title: "",
        accentColor: "#1064E3",
        url: "./authorize.html",
        height: 593,
        args: { trigger: "onEnable" },
      });
    },
    "authorization-status": async function (t, options) {
      const token = await t.get("member", "private", "token");
      if (!token || token.includes("rejected")) {
        return { authorized: false };
      } else {
        return { authorized: true };
      }
    },
    "show-authorization": function (t, opts) {
      return t.modal({
        title: "",
        accentColor: "#1064E3",
        url: "./authorize.html",
        height: 593,
        args: { trigger: "show-authorization" },
      });
    },
  },
  {
    appKey: process.env.POWERUP_KEY,
    appName: process.env.POWERUP_NAME,
    appAuthor: "Native Power-Ups",
  }
);

const openModal = async (t) => {
  const context = t.getContext();
  const theme = context.initialTheme;
  const boardId = context.board;
  const background = await getBoardBackground(t, boardId);
  const adjustedTopColor = hexToAdjustedHex(
    background.topColor,
    background.brightness
  );

  return t.modal({
    title: process.env.POWERUP_NAME,
    url: "./modal.html",
    accentColor:
      theme === "dark" ? "#161A1D" : background ? adjustedTopColor : "",
    fullscreen: true,
  });
};
