// Convert HEX to RGB
function hexToRGB(hex) {
  return {
    r: parseInt(hex.slice(1, 3), 16),
    g: parseInt(hex.slice(3, 5), 16),
    b: parseInt(hex.slice(5, 7), 16),
  };
}

// Convert RGB to HSL
function rgbToHSL({ r, g, b }) {
  r /= 255;
  g /= 255;
  b /= 255;

  let max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max == min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return { h: Math.round(h * 360), s: s * 100, l: l * 100 };
}

// Adjust HSL for light background
function adjustHSLForLightBackground(hsl) {
  hsl.s = hsl.s * 0.9; // reduce saturation
  hsl.l = hsl.l + (100 - hsl.l) * 0.05; // slightly increase lightness
  return hsl;
}

// Adjust HSL for dark background
function adjustHSLForDarkBackground(hsl) {
  hsl.s = hsl.s * 1.1; // increase saturation
  hsl.l = hsl.l * 0.35; // decrease lightness
  return hsl;
}

// Convert HSL to RGB
function hslToRGB({ h, s, l }) {
  s /= 100;
  l /= 100;

  let c = (1 - Math.abs(2 * l - 1)) * s;
  let x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  let m = l - c / 2;
  let r = 0,
    g = 0,
    b = 0;

  if (h >= 0 && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (h >= 60 && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (h >= 120 && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (h >= 180 && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (h >= 240 && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (h >= 300 && h < 360) {
    r = c;
    g = 0;
    b = x;
  }

  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  return { r, g, b };
}

// Convert RGB to HEX
function rgbToHex({ r, g, b }) {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
}

export function hexToAdjustedHex(hex, background) {
  let rgb = hexToRGB(hex);
  let hsl = rgbToHSL(rgb);

  if (background === "light") {
    hsl = adjustHSLForLightBackground(hsl);
  } else if (background === "dark") {
    hsl = adjustHSLForDarkBackground(hsl);
  }

  rgb = hslToRGB(hsl);
  return rgbToHex(rgb);
}
