const apiKey = process.env.POWERUP_KEY;

export async function getBoardBackground(t, boardId) {
  const token = await t.get("member", "private", "token");

  if (!token || token.includes("rejected")) {
    return null;
  } else {
    return fetch(
      `https://api.trello.com/1/boards/${boardId}?fields=prefs&key=${apiKey}&token=${token}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.prefs) {
          return {
            url: data.prefs.backgroundImage,
            color: data.prefs.backgroundColor,
            topColor: data.prefs.backgroundTopColor,
            brightness: data.prefs.backgroundBrightness,
          };
        }
        return null;
      })
      .catch((error) => {
        console.error("Failed to fetch Trello board background:", error);
        return null;
      });
  }
}

export async function getCardStatusAndData(t, cardId) {
  const token = await t.get("member", "private", "token");

  if (!token || token.includes("rejected")) {
    return null;
  } else {
    return fetch(
      `https://api.trello.com/1/cards/${cardId}?checklists=all&members=true&key=${apiKey}&token=${token}`
    )
      .then(async (response) => {
        const status = response.status; // Capture the status

        // Check if the response is OK (status in range 200-299)
        if (response.ok) {
          const data = await response.json(); // Parse the JSON data
          return { status, data }; // Return both status and data
        }

        // For other error statuses (not handled above), return status and no data
        return { status, data: null };
      })
      .catch((error) => {
        console.error("Failed to fetch card status:", error);
        return { status: "error", data: null }; // Return an error status and null data for network issues
      });
  }
}

// Batch version of getCardStatusAndData for multiple cardIds
export async function batchGetCardStatusAndData(t, cardIds) {
  const token = await t.get("member", "private", "token");

  if (!token || token.includes("rejected")) {
    return null;
  }

  const batchUrls = cardIds.map((cardId) =>
    encodeURIComponent(`/cards/${cardId}?checklists=all&members=true`)
  );

  // Split the cardIds into batches of 10 (as the batch API allows max 10 requests at a time)
  const batchedUrls = [];
  while (batchUrls.length) {
    batchedUrls.push(batchUrls.splice(0, 10));
  }

  const allResults = [];

  // Fetch data in batches using Trello's batch API
  await Promise.all(
    batchedUrls.map(async (batch) => {
      const batchResponse = await fetch(
        `https://api.trello.com/1/batch?urls=${batch.join(
          ","
        )}&key=${apiKey}&token=${token}`
      );
      const batchData = await batchResponse.json();

      // Process each response
      batchData.forEach((response, index) => {
        // Safely extract cardId from the batch URL
        const urlParts = decodeURIComponent(batch[index]).match(
          /\/cards\/([^?]+)/
        );
        const cardId = urlParts ? urlParts[1] : null;

        if (response[200]) {
          allResults.push({ id: cardId, status: 200, data: response[200] });
        } else if (response.statusCode === 401 || response.statusCode === 404) {
          allResults.push({
            id: cardId,
            status: response.statusCode,
            data: null,
          });
        } else {
          allResults.push({ id: cardId, status: "error", data: null });
        }
      });
    })
  );

  return allResults; // Array of results for all cardIds
}

export async function postCardToTrello(t, cardName, selectedListId) {
  const token = await t.get("member", "private", "token");

  if (!token || token.includes("rejected")) {
    return null;
  } else {
    const url = `https://api.trello.com/1/cards?name=${encodeURIComponent(
      cardName
    )}&idList=${selectedListId}&key=${apiKey}&token=${token}`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error posting card to Trello:", error);
    }
  }
}

export async function getOrgData(t) {
  const organization = await t.organization("id");
  const organizationId = organization.id;
  const token = await t.get("member", "private", "token");
  if (!token || token.includes("rejected")) {
    return null;
  }

  let orgData = {
    boards: [],
    lists: [],
    cards: [],
  };

  // Find boards that the member has access to for this organization
  const boardOptions = await getBoards(token, organizationId);
  orgData.boards = boardOptions;

  // Prepare batch requests for lists and cards, ensuring proper URI encoding
  const batchUrls = [];
  boardOptions.forEach((board) => {
    batchUrls.push(encodeURIComponent(`/boards/${board.id}/lists?filter=open`));
    batchUrls.push(
      encodeURIComponent(
        `/boards/${board.id}/cards?filter=visible&checklists=all&members=true`
      )
    );
  });

  // Split into batches of 10 requests
  const batchedUrls = [];
  while (batchUrls.length) {
    batchedUrls.push(batchUrls.splice(0, 10));
  }

  // Use Promise.all to fetch data in batches
  await Promise.all(
    batchedUrls.map(async (batch) => {
      const batchResponse = await fetch(
        `https://api.trello.com/1/batch?urls=${batch}&key=${apiKey}&token=${token}`
      );
      const batchData = await batchResponse.json();

      // Process each response in the batchData array
      batchData.forEach((response, index) => {
        const route = decodeURIComponent(batch[index]);

        if (response?.[200]) {
          if (route.includes("/lists")) {
            // Process lists
            orgData.lists = orgData.lists.concat(response[200]);
          } else if (route.includes("/cards")) {
            // Process cards
            orgData.cards = orgData.cards.concat(response[200]);
          }
        } else {
          // Log the entire response for debugging
          console.error(`Error fetching data for ${route}:`, response);
        }
      });
    })
  );

  return orgData;
}

async function getBoards(token, organizationId) {
  const fields = ["id", "name", "idOrganization", "url"];

  if (!token || token.includes("rejected")) {
    return null;
  } else {
    return fetch(
      `https://api.trello.com/1/organizations/${organizationId}/boards?fields=${fields}&filter=open&key=${apiKey}&token=${token}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          return data;
        }
        return null;
      })
      .catch((error) => {
        console.error("Failed to fetch org boards:", error);
        return null;
      });
  }
}
